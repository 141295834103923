$(".nav__toggle").on("click", function(e){
  const parent = $(this).closest(".nav");
  if(parent.hasClass("nav--active")){
    $("body").removeClass("no-scroll");
  }
  else{
    $("body").addClass("no-scroll");
  }
  parent.toggleClass("nav--active");
  $(this).addClass("nav__toggle--hidden");
  $(".nav__toggle").not($(this)).removeClass("nav__toggle--hidden");
});

if($(window).width() < 975){
  // Show navbar item sublist when clicked
  $(".nav__item.group > .nav__link").on("click", function(e){
    e.preventDefault();
    e.stopPropagation();
    $(".nav__back").removeClass("nav__back--hidden");
    $(this).parent().siblings().css("display", "none");
    $(this).addClass("nav__link--active");
    $(this).siblings(".nav__sublist").addClass("nav__sublist--active");
  });
  $(".nav__back").on("click", function(e){
    $(this).addClass("nav__back--hidden");
    $(".nav__item.group .nav__link").removeClass("nav__link--active");
    $(".nav__sublist").removeClass("nav__sublist--active");
    $(".nav__item").css("display", "block");
  });
}
$(window).resize(function(){
  if($(window).width() < 975){
    // Show navbar item sublist when clicked
    $(".nav__item.group > .nav__link").on("click", function(e){
      e.preventDefault();
      e.stopPropagation();
      $(".nav__back").removeClass("nav__back--hidden");
      $(this).parent().siblings().css("display", "none");
      $(this).addClass("nav__link--active");
      $(this).siblings(".nav__sublist").addClass("nav__sublist--active");
    });
    $(".nav__back").on("click", function(e){
      $(this).addClass("nav__back--hidden");
      $(".nav__item.group .nav__link").removeClass("nav__link--active");
      $(".nav__sublist").removeClass("nav__sublist--active");
      $(".nav__item").css("display", "block");
    });
  }
  else{
    $(".nav__item.group > .nav__link").off("click");
    $(".nav__back").addClass("nav__back--hidden");
    $(".nav__item.group .nav__link").removeClass("nav__link--active");
    $(".nav__sublist").removeClass("nav__sublist--active");
    $(".nav__item").css("display", "block");
    $(".nav").removeClass("nav--active");
  }
});